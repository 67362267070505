/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useEffect, useMemo } from 'react';
import Button from 'components/core/button';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CreateQuest, QuestEvent } from 'api/quests';
import DateTimePicker, { DatePickerSize } from 'components/core/datepicker';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import FormField from 'components/form/form-field';
import { joiResolver } from '@hookform/resolvers/joi';
import { useIntlValidation } from 'hooks/intl/validation';
import joi from 'joi';
import FormSelect from 'components/form/form-select';
import { debounce } from 'lodash';
import { QuestsModel } from 'models/QuestModel';
import { Events } from './Events';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDeleteQuest } from './DeleteModal';

export type CreateQuestForm = {
  name: string;
  reward: number;
  budget: number;
  delay: number;
  templateUrl: string;
  currency: string;
  events: QuestEvent[];
  startedAt: Date;
  expiredAt: Date;
  accountId: string;
};

type Props = {
  quest?: QuestsModel;
  onCreate: (quest: CreateQuest) => Promise<void>;
  onDelete?: (questId: string) => void;
};

const SidebarQuestCreate = observer(({ quest, onCreate, onDelete }: Props) => {
  const intl = useIntl();

  const { layoutStore, accountsStore, currencyStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const accounts = accountsStore.accounts.map((id) => ({
    id: id,
    value: id,
    label: id,
  }));

  const { validationOptions } = useIntlValidation();

  const formScheme = useMemo(
    () =>
      joi.object<CreateQuestForm>({
        events: joi.any(),
        name: joi.string().empty('').required(),
        accountId: joi.string().empty('').required(),
        currency: joi.string().empty('').required(),
        templateUrl: joi.required().custom((value, helpers) => {
          // Проверяем, что это валидный URL и содержит параметр quest
          const urlPattern = /^.*\{\}.*$/;
          if (!urlPattern.test(value)) {
            return helpers.error('uri.pattern');
          }
          return value;
        }),

        reward: joi.number().min(0).max(Number.MAX_SAFE_INTEGER).required(),
        budget: joi.number().min(0).max(Number.MAX_SAFE_INTEGER).required(),
        delay: joi.number().min(0).max(Number.MAX_SAFE_INTEGER).required(),
        startedAt: joi.date().required(),
        expiredAt: joi
          .date()
          .required()
          .when('startedAt', {
            is: joi.exist(),
            then: joi.date().greater(joi.ref('startedAt')).messages({
              'date.greater': 'The End Date must be greater than Start Date',
            }),
          }),
      }),
    []
  );

  const form = useForm<CreateQuestForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: quest
      ? {
          name: quest.name,
          reward: quest.reward,
          budget: quest.budget,
          delay: quest.delay,
          templateUrl: quest.templateUrl,
          currency: quest.currency,
          events: quest.events,
          accountId: quest.accountId,
          startedAt: quest.startedAt ? new Date(quest.startedAt) : undefined,
          expiredAt: quest.expiredAt ? new Date(quest.expiredAt) : undefined,
        }
      : {
          events: [
            {
              id: undefined,
              name: 'Event_Name',
            },
          ],
        },
  });
  const { handleSubmit, control, reset, resetField } = form;

  const onSubmit = useCallback(
    async (values: CreateQuestForm) => {
      await onCreate({
        ...values,
        startedAt: values.startedAt.toISOString(),
        expiredAt: values.expiredAt.toISOString(),
      });
      reset();
      layoutStore.toggleSidebar(false);
    },
    [layoutStore, setFilterQueryParams]
  );

  const handleDelete = async () => {
    if (!quest) return;
    onDelete?.(quest.id);
    reset();
    layoutStore.toggleSidebar(false);
  };

  const { showDeleteQuestModal } = useDeleteQuest();

  const [startedAt, expiredAt, accountId] = useWatch({
    control,
    name: ['startedAt', 'expiredAt', 'accountId'],
  });

  const loadBalances = useMemo(
    () => debounce(accountsStore.accountBalances.fetch, 500),
    [accountsStore.accountBalances]
  );

  useEffect(() => {
    if (accountId) loadBalances(accountId);

    return () => {
      accountsStore.accountBalances.reset();
      resetField('currency');
    };
  }, [loadBalances, accountId]);

  const selectOptions = accountsStore.accountBalances.data.map((item) => ({
    id: item.currency,
    value: item.currency,
    label: item.currency,
    icon: currencyStore.currenciesMap.get(item.currency)?.iconUrl,
  }));

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Controller
          control={control}
          name='name'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.create_quest.name',
                defaultMessage: 'Name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_quest.name.placeholder',
                defaultMessage: 'Quest name',
              })}
              variant='secondary'
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='accountId'
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <FormSelect
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.account_id',
                  defaultMessage: 'Account ID',
                })}
                variant='borderless'
                options={accounts}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.account_id',
                  defaultMessage: 'Account ID',
                })}
                onChange={(value) => onChange(value?.value as string)}
                value={accounts.find((item) => item.value === value)}
                error={error?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='currency'
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <FormSelect
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.currency',
                  defaultMessage: 'Currency',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_quest.currency.placeholder',
                  defaultMessage: 'Currency',
                })}
                variant='borderless'
                options={selectOptions}
                value={selectOptions.find((item) => item.value === value)}
                onChange={(value) => onChange(value?.value as string)}
                error={error?.message}
              />
            );
          }}
        />

        <div className={styles.two_cols}>
          <div className={styles.col}>
            <h5>
              {intl.formatMessage({
                id: 'sidebar.create_quest.start',
                defaultMessage: 'Start',
              })}
            </h5>
            <Controller
              control={control}
              name='startedAt'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DateTimePicker
                  placeholder='Date and time'
                  dateFormat={{
                    format: 'YYYY-MM-DD HH:mm:ss',
                    type: 'mask',
                  }}
                  size={DatePickerSize.Large}
                  onChange={onChange}
                  value={value ? new Date(value) : undefined}
                  maxDate={expiredAt}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className={styles.col}>
            <h5>
              {intl.formatMessage({
                id: 'sidebar.create_quest.end',
                defaultMessage: 'End',
              })}
            </h5>
            <Controller
              control={control}
              name='expiredAt'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DateTimePicker
                  placeholder='Date and time'
                  dateFormat={{
                    format: 'YYYY-MM-DD HH:mm:ss',
                    type: 'mask',
                  }}
                  size={DatePickerSize.Large}
                  onChange={onChange}
                  value={value ? new Date(value) : undefined}
                  minDate={startedAt}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>
        <FormProvider {...form}>
          <DndProvider backend={HTML5Backend}>
            <Events />
          </DndProvider>
        </FormProvider>

        <Controller
          control={control}
          name='delay'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.create_quest.delay',
                defaultMessage: 'Delay between events',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_quest.name.placeholder',
                defaultMessage: '60s',
              })}
              variant='secondary'
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <div className={styles.two_cols}>
          <Controller
            control={control}
            name='reward'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.reward',
                  defaultMessage: 'Reward',
                })}
                placeholder='$60'
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='budget'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.budget',
                  defaultMessage: 'Budget',
                })}
                placeholder='$100'
                variant='secondary'
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name='templateUrl'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.create_quest.link',
                defaultMessage: 'Quest link',
              })}
              placeholder='https://www.name.com/?quest={}'
              variant='secondary'
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>
      {quest ? (
        <div className={styles.buttons}>
          <Button
            className={styles.apply_btn}
            variant='negative'
            onClick={() => showDeleteQuestModal(handleDelete)}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.delete',
              defaultMessage: 'Delete quest',
            })}
          </Button>
          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={handleSubmit(onSubmit)}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.save',
              defaultMessage: 'Save changes',
            })}
          </Button>
        </div>
      ) : (
        <Button
          className={styles.apply_btn}
          variant='primary'
          onClick={handleSubmit(onSubmit)}
        >
          {intl.formatMessage({
            id: 'sidebar.quest_create.create',
            defaultMessage: 'Create',
          })}
        </Button>
      )}
    </React.Fragment>
  );
});

export const useSidebarCreateQuest = ({ quest, onCreate, onDelete }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.quest',
        defaultMessage: quest ? 'Edit quest info' : 'Create a quest',
      }),
      <SidebarQuestCreate
        quest={quest}
        onCreate={onCreate}
        onDelete={onDelete}
      />
    );
  }, [quest, intl, layoutStore, onCreate, onDelete]);

  return {
    showCreateQuestSidebar: showSidebar,
  };
};
